import React,  { useEffect, useState }  from 'react'
import './waves.css';
import TypeWriterEffect from 'react-typewriter-effect';
import './style.css';

const Node = ({data}) => {

	return (

	    <div className="App ">

        <div class="context flex justify-center items-center bg-gradient-to-b from-indigo-400 via-indigo-500 to-blue-500 min-h-screen">
           <h1 class="max-w-5xl mb-4 text-5xl text-white font-extrabold animate-fade animate-delay-700 animate-duration-[4000ms] animate-once">
           {data.title}
           </h1>
        </div>


        <div class="area" >
                <ul class="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                </ul>
        </div >


        </div>

	)
}

export default Node
import React,  { useEffect, useState }  from 'react'
import './waves.css';

const Intro = ({data}) => {


	return (
		<div className="App">
        <div class="context flex justify-center items-center bg-gradient-to-b from-indigo-400 via-indigo-500 to-blue-500 min-h-screen">
        <section>
            <div class="grid max-w-screen-xl px-4 py-8 lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                {data.image &&
                    <div class="lg:mt-0 lg:col-span-4 lg:flex justify-center items-center">
                        <img className="rounded-full max-w-[340px] max-h-[340px] " src={data.image} alt="mockup"/>
                    </div>
                }
                <div class="mr-auto place-self-center lg:col-span-8 p-10 ">
                    <h1 class="max-w-2xl mb-4 text-4xl text-white font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">{data.name}</h1>
                    {data.tagline &&
                        <h5 class="max-w-2xl mb-4 text-3xl text-white font-extrabold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white">
                           {data.tagline}
                        </h5>
                    }
                    <hr className="mb-5 text-indigo-900"/>
                    <h5 class="max-w-2xl mb-4 text-xl text-white font-extrabold tracking-tight leading-none md:text-2xl xl:text-2xl dark:text-white">
                    {data.title}</h5>
                    <p class="max-w-2xl mb-6 text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                        {data.description}
                    </p>
                    <h5 class="max-w-2xl mb-4 text-3xl text-white font-extrabold tracking-tight leading-none md:text-3xl xl:text-3xl dark:text-white">
                        {data.date}
                    </h5>
                </div>


            </div>
        </section>
        </div>

                <div class="area" >
                        <ul class="circles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                        </ul>
                </div >
        </div>
	)
}

export default Intro
import Node from './components/Node'
import Intro from './components/Intro'
import Outro from './components/Outro'

import { useSearchParams } from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  useParams,
} from "react-router-dom";


let router = createBrowserRouter([
  {
    path: "intro/:encoded",
    Component() {
      let params = useParams();
      let encoded = params.encoded
      let data = {}
      if(encoded){
         data = JSON.parse(atob(encoded));
      }
      return <Intro data={data}/>;
    },
  },
  {
      path: "node/:encoded",
      Component() {
        let params = useParams();
        let encoded = params.encoded
        let data = {}
        if(encoded){
           data = JSON.parse(atob(encoded));
        }
        return <Node data={data}/>;
      },
    },
    {
      path: "outro/:encoded",
      Component() {
        let params = useParams();
        let encoded = params.encoded
        let data = {}
        if(encoded){
           data = JSON.parse(atob(encoded));
        }
        return <Outro data={data}/>;
      },
    },
]);


function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

export default App;
